import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';

export interface Card {
  title: string;
  href: string;
  date: string;
  datetime: string;
  image_url: string;
  new_tab: boolean;
}

interface Props {
  cards: Card[];
}

export default function CardGrid(props: Props) {
  return (
    <div className="relative bg-opacity-10 px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        {/* {(props.header || props.subheader) && (
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {props.header}
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
              {props.subheader}
            </p>
          </div>
        )} */}
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {props.cards.map((card) => (
            <a
              href={card.href}
              target={card.new_tab ? '_blank' : ''}
              key={card.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 duration-300"
              rel="noreferrer"
            >
              <div className="flex-shrink-0" style={{ width: '340px' }}>
                <img
                  className="img-fluid h-48 w-full object-cover"
                  src={card.image_url}
                  alt={''}
                />
              </div>

              <div
                className=" d-flex flex-column enter align-items-center bg-gray-800 p-6"
                style={{
                  height: '220px',
                  width: '340px',
                  display: 'grid',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div className="mt-2 block ">
                    <p className="text-xl font-semibold text-gray-200 text-center">
                      {card.title.length > 61
                        ? `${card.title.substring(0, 58)}...`
                        : card.title}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex">
                  <div className="flex space-x-1 text-sm text-gray-400">
                    <time dateTime={card.datetime}>{card.date}</time>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
